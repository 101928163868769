import React from "react";
import Parent from "./components/Parent";

function App() {
	return (
		<div className="App">
			<Parent />
		</div>
	);
}

export default App;
